import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfiguredAuth, logoutWithAmplify } from '~/helpers/auth';
import config from '~/config';

const rawBaseQuery = (baseUrl) => fetchBaseQuery({
  baseUrl,
  prepareHeaders: async (headers) => {
    try {
      const session = await getConfiguredAuth().currentSession();
      const token = session.getIdToken().getJwtToken();
      if (!token) {
        await logoutWithAmplify();
        window.location.href = '/login';
        return headers;
      }
      headers.set('authorization', `Bearer ${token}`);
    } catch (error) {
      await logoutWithAmplify();
      window.location.href = '/login';
    }
    return headers;
  },
});

const dynamicBaseQuery = async (args, api, extraOptions) => {
  const baseUrl = config.apiEndpoint;
  if (!baseUrl) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No host found',
      },
    };
  }
  try {
    const response = await rawBaseQuery(baseUrl)(args, api, extraOptions);
    // If the response status is 401 (Unauthorized), trigger the signOut process
    if (response.error && response.error.status === 401) {
      await logoutWithAmplify();
      window.location.href = '/login';
      return response;
    }
    return response;
  } catch (error) {
    // Catch any unexpected errors
    return { error: { message: 'An error occurred while making the request' } };
  }
};

// Base API definition
export const api = createApi({
  reducerPath: 'api',
  baseQuery: dynamicBaseQuery,
  tagTypes: ['Users', 'Tenants', 'Notifications', 'News', 'Article'],
  endpoints: () => ({}),
  refetchOnFocus: true,
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
});

// Users api endpoints
export const currentUserApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchProfile: builder.mutation({
      query() {
        return {
          url: '/me',
          method: 'GET',
        };
      },
    }),
    fetchDetail: builder.query({
      query: () => '/me/detail',
    }),
  }),
});

// Users api endpoints
export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchUsers: builder.query({
      query: () => '/users',
      providesTags: ['Users'],
    }),
    disableUser: builder.mutation({
      query(userId) {
        return {
          url: `users/${userId}/disable`,
          method: 'PATCH',
        };
      },
      invalidatesTags: [{ type: 'Users' }],
    }),
    enableUser: builder.mutation({
      query(userId) {
        return {
          url: `users/${userId}/enable`,
          method: 'PATCH',
        };
      },
      invalidatesTags: [{ type: 'Users' }],
    }),
    removeUser: builder.mutation({
      query(userId) {
        return {
          url: `users/${userId}/remove`,
          method: 'PATCH',
        };
      },
      invalidatesTags: [{ type: 'Users' }],
    }),
    updateUser: builder.mutation({
      query({ userId, body }) {
        return {
          url: `user/${userId}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: [{ type: 'Users' }],
    }),
    promoteUser: builder.mutation({
      query(userId) {
        return {
          url: `users/${userId}/promote`,
          method: 'PATCH',
        };
      },
      invalidatesTags: [{ type: 'Users' }],
    }),
    demoteUser: builder.mutation({
      query(userId) {
        return {
          url: `users/${userId}/demote`,
          method: 'PATCH',
        };
      },
      invalidatesTags: [{ type: 'Users' }],
    }),
  }),
});

// Notifications api endpoints
export const notificationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchNotifications: builder.query({
      query: () => '/notifications',
      providesTags: ['Notifications'],
    }),
    createNotification: builder.mutation({
      query({ notification, recipients }) {
        return {
          url: 'notification',
          method: 'POST',
          body: { notification, recipients },
        };
      },
      invalidatesTags: [{ type: 'Notifications' }],
    }),
  }),
});

// Tenants api endpoints
export const tenantsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchTenants: builder.query({
      query: () => '/tenants',
      providesTags: ['Tenants'],
    }),
    createTenant: builder.mutation({
      query(tenant) {
        return {
          url: 'tenant',
          method: 'POST',
          body: tenant,
        };
      },
      invalidatesTags: [{ type: 'Tenants' }],
    }),
    disableTenant: builder.mutation({
      query(tenantId) {
        return {
          url: `tenant/${tenantId}/disable`,
          method: 'PATCH',
        };
      },
      invalidatesTags: [{ type: 'Tenants' }],
    }),
    enableTenant: builder.mutation({
      query(tenantId) {
        return {
          url: `tenant/${tenantId}/enable`,
          method: 'PATCH',
        };
      },
      invalidatesTags: [{ type: 'Tenants' }],
    }),
  }),
});

// News api endpoints
export const newsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllArticles: builder.query({
      query: () => '/news-articles',
      providesTags: ['News'],
    }),
    fetchArticle: builder.query({
      query: (newsId) => `/news-article/${newsId}`,
      providesTags: ['Article'],
    }),
    createArticle: builder.mutation({
      query(article) {
        return {
          url: 'news-article',
          method: 'POST',
          body: article,
        };
      },
      invalidatesTags: [{ type: 'News' }],
    }),
    updateArticle: builder.mutation({
      query({ article, newsId }) {
        return {
          url: `news-article/${newsId}`,
          method: 'PATCH',
          body: article,
        };
      },
      invalidatesTags: [{ type: 'Article' }, { type: 'News' }],
    }),
    removeArticle: builder.mutation({
      query(newsId) {
        return {
          url: `news-article/${newsId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'News' }],
    }),
  }),
});

export const {
  useFetchProfileMutation, useFetchDetailQuery,
} = currentUserApi;

export const {
  useFetchUsersQuery,
  useDisableUserMutation,
  useEnableUserMutation,
  useRemoveUserMutation,
  useUpdateUserMutation,
  usePromoteUserMutation,
  useDemoteUserMutation,
} = usersApi;

export const {
  useFetchNotificationsQuery, useCreateNotificationMutation,
} = notificationsApi;

export const {
  useFetchTenantsQuery,
  useCreateTenantMutation,
  useDisableTenantMutation,
  useEnableTenantMutation,
} = tenantsApi;

export const {
  useFetchAllArticlesQuery,
  useFetchArticleQuery,
  useCreateArticleMutation,
  useUpdateArticleMutation,
  useRemoveArticleMutation,
} = newsApi;
