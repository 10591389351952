import { ChakraProvider } from '@chakra-ui/react';
import {
  RouterProvider,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import '@fontsource/comfortaa/';
import '@fontsource/montserrat/';

import theme from '~/assets/theme';
import router from './routes';
import store, { persistor } from './store';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={theme}>
          <RouterProvider router={router} />
        </ChakraProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
